<template>
    <div class="public-view" ref="public-view">
        <div class="public-view-header" :class="{scroll}">
            <div class="public-view-header-box">
                <img class="logo" src="@/assets/logo.png" alt="">
                <div class="public-view-header-operate">
                    <el-input
                        class="public-view-header-operate-search"
                        placeholder="关键词模糊搜索"
                        @keyup.enter.native="search"
                        v-model="searchValue">
                        <template #suffix>
                            <i class="el-icon-search" @click="iconSeach"></i>
                        </template>
                    </el-input>
                    <div class="public-view-header-operate-tabs">
                        <div @click="opearte(item, index)" :class="{active: activeIndex == index + 1}" v-for="(item, index) in tabList" :key="index">{{ item.label }}</div>
                    </div>
                    <!-- <el-dropdown @command="topRojectDetail" placement="top">
                        <span class="el-dropdown-link">
                            <el-link type="primary" :underline="false">找项目</el-link>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="行业分类">行业分类</el-dropdown-item>
                            <el-dropdown-item command="财务数据">财务数据</el-dropdown-item>
                            <el-dropdown-item command="所在地">所在地</el-dropdown-item>
                            <el-dropdown-item command="isHot">热门项目</el-dropdown-item>
                            <el-dropdown-item command="isRecommend">推荐项目</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <el-link type="primary" :underline="false" @click="release">项目发布</el-link> -->
                </div>
                <el-dropdown @command="handlerClick">
                    <div class="public-view-header-user">
                        <el-avatar :size="scroll ? 30 : 60" shape="circle" :src="user.avatar">
                            <img src="@/assets/defaultAvatar.png" alt="">
                        </el-avatar>
                        <p class="name">{{ user.nickname }}</p>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="login" v-if="!user.id">登录</el-dropdown-item>
                        <template v-else>
                            <el-dropdown-item command="personalCenterClient">个人中心</el-dropdown-item>
                            <el-dropdown-item command="console"
                                v-if="[1, 3].includes(user.userType)">控制台</el-dropdown-item>
                            <el-dropdown-item command="loginOut">退出</el-dropdown-item>
                        </template>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <el-drawer :title="operateKey" :visible.sync="drawerVisible" direction="rtl" :before-close="drawerHandleClose">
            <el-cascader-panel :key="operateKey" class="operatePanel" :popper-class="'operatePanel'" :options="drawerOptions.list"
                :props="drawerOptions.map" @change="change"></el-cascader-panel>
        </el-drawer>
        <release ref="release" />
        <router-view class="router-view" :key="$route.fullPath" />
    </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import getLabelsMixins from '@/pages/layout/getLabelsMixins.js'
import release from '@/pages/clientRelease/index.vue'
export default {
    data() {
        return {
            scroll: false,
            operateKey: '',
            drawerVisible: false,
            searchValue: '',
            activeIndex: '',
            tabList: [
                {
                    label: '首页',
                    path: '/indexHome'
                },
                {
                    label: '找项目',
                    path: '/clientProjectList'
                },
                {
                    label: '热门项目',
                    path: '/clientProjectList',
                    query: {
                        isHot: 1
                    }
                },
                {
                    label: '推荐项目',
                    path: '/clientProjectList',
                    query: {
                        isRecommend: 1
                    }
                },
                {
                    label: '项目发布',
                    path: '',
                    handlerClick: this.release
                },
            ]
        }
    },
    mixins: [getLabelsMixins],
    components: {
        release
    },
    watch: {
        '$route': {
            handler() {
                this.tabList.forEach((item, index) => {
                    const { path, query = {} } = item
                    const { path: routePath, query: routeQuery } = this.$route
                    if (path == routePath && Object.keys(query).every(key => routeQuery[key] == query[key])) {
                        this.activeIndex = index + 1
                    }
                });
            },
            immediate: true
        }
    },
    async mounted() {
        document.title = '创投小圈子-创投人的聚集中心'
        await this.$nextTick()
        const publicView = this.$refs['public-view']
        const scroll = () => {
            this.scroll = publicView.scrollTop > 20
        }
        publicView.addEventListener('scroll', scroll)
        this.$once('hook:beforeDestroy', () => {
            publicView.removeEventListener('scroll', scroll);
        });
    },
    computed: {
        ...mapState(['user']),
        selects() {
            return [1, 2, 3].map(i => this.selectArr.find(item => item.value === i))
        },
        drawerOptions() {
            const { operateKey, pickerOptions } = this
            return pickerOptions[operateKey] ? pickerOptions[operateKey] : {
                map: {},
                list: [],
                key: '',
                params: {}
            }
        },
        pickerOptions() {
            const { businessIncomeOptions = [], netProfitOptions = [], industryOptions = [] } = this.options
            const { geoLogyOptions = [] } = this
            return {
                '行业分类': {
                    map: { label: 'labelName', value: 'id' },
                    list: industryOptions,
                    key: 'industry',
                    params: {
                        title: '行业分类'
                    }
                },
                '财务数据': {
                    map: { label: 'labelName', value: 'id' },
                    list: [
                        {
                            id: 'businessIncome',
                            labelName: '最近财年营业总收入',
                            children: businessIncomeOptions
                        },
                        {
                            id: 'netProfit',
                            labelName: '最近财年净利润',
                            children: netProfitOptions
                        }

                    ],
                    title: '财务数据'
                },
                '所在地': {
                    map: { label: 'name', value: 'id' },
                    key: 'cityCode',
                    list: geoLogyOptions,
                    title: '所在地'
                }
            }
        }
    },
    methods: {
        ...mapMutations(['setUser', 'setToken']),
        iconSeach() {
            if (!this.searchValue) return
            this.search()
        },
        async topRojectDetail(e) {
            await this.$store.state.clientLoginRef.init()
            if (['行业分类', '财务数据', '所在地'].includes(e)) {
                this.operateKey = e
                this.drawerVisible = true
            } else {
            this.$router.push({
                path: '/clientProjectList',
                query: {
                    [e]: 1
                }
            })
            }
        },
        search() {
            console.log(123)
            this.$router.push({
                path: '/clientProjectList',
                query: {
                    enterpriseName: this.searchValue
                }
            })
        },
        opearte({ path, query = {}, handlerClick } ,index) {
            if (path) {
                this.activeIndex = index + 1
            }
            if (path) {
                return this.$router.push({
                    path,
                    query
                })
            }
            handlerClick && handlerClick()

        },
        // setActive({ path, query = {} }) {
        //     console.log(path, query)
        // },
        async release() {
            await this.$store.state.clientLoginRef.init()
            this.$refs.release.init()

        },
        drawerHandleClose() {
            this.drawerVisible = false
        },
        change(e) {
            const { key = undefined } = this.drawerOptions
            let query = {}
            if(key == 'industry') {
                query[key] = e.slice(-1)[0]
            } else if (key === 'cityCode') {
                query.provinceCode = e[0]
                query[key] = e[1]
            } else{
                query[e[0]] = e[1]
            }
            this.drawerVisible = false
            this.$router.push({
                path: '/clientProjectList',
                query
            })
            
        },
        handlerClick(e) {
            switch (e) {
                case 'login':
                    this.$store.state.clientLoginRef.init()
                    break
                case 'personalCenterClient':
                    this.$router.push({
                        path: '/personalCenterClient'
                    })
                    break
                case 'console':
                    this.$router.push({
                        path: this.user.userType == 1 ? "/home" : "/project"
                    })
                    break
                case 'loginOut':
                    this.$router.replace({
                        path: '/indexHome'
                    })
                    localStorage.clear()
                    this.setUser({})
                    this.setToken('')
            }
        }
    }
}
</script>
<style lang="less" scoped>
.public-view {
    height: 100vh;
    // padding-top: 130px;
    box-sizing: border-box;
    overflow-y: auto;
    background-color: #f5f5f5;

    &-header {
        width: 100%;
        height: 67px;
        // transition: all .5s;
        box-shadow: 0 3px 3px #f2f2f2;
        background-color: #fff;
        &.scroll{
            height: 60px;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 11;
            .logo{
                width: 40px;
            }
        }

        &-box {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;

            .logo {
                width: 60px;
                height: auto;
            }

            .public-view-header-user {
                display: flex;
                align-items: center;

                .name {
                    margin-left: 10px;
                }
            }

            .public-view-header-operate {
                flex: 1;
                width: 140px;
                display: flex;
                justify-content: space-between;
                margin: 0 46px;
                gap: 20px;
                &-search{
                    width: 267px;
                    .el-icon-search{
                        font-size: 20px;
                        margin-top: 10px;
                        margin-right: 17px;
                        cursor: pointer;
                    }
                }
                &-tabs{
                    display: flex;
                    align-items: center;
                    color: #2e3742;
                    font-size: 16px;
                    gap: 0 50px;
                    div{
                        cursor: pointer;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        position: relative;
                    }
                    .active{
                        color: #1C68FF;
                        &::after{
                            content: '';
                            height: 2px;
                            border-radius: 1px;
                            background-color: #1C68FF;
                            width: 120%;
                            position: absolute;
                            bottom: 0;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }
            }
        }

        &.scroll {}
    }
    /deep/ .el-drawer{
        width: auto !important;
    }
    .operatePanel{
        height: 100%;
        /deep/ .el-cascader-menu__wrap{
            height: 100%;
        }

    }
}
</style>