
import { mapState, mapMutations } from 'vuex'
export default {
	data() {
		return {
			selectArr: [
				{key: 'industry', title: '行业关键词', value: 1, limit: 1 },
				{key: 'businessIncome', title: '最近年营收', value: 2, limit: 2 },
				{key: 'netProfit', title: '最近净利润', value: 3, limit: 2 },
				{key: 'institutionalBackgrounds', title: '机构背景', value: 4},
				{key: 'focusAreas', title: '关注领域', value: 5, limit: 1 },
				{key: 'preferenceRound', title: '偏好伦次', value: 6, limit: 1 }
			],
			options: {
				institutionalBackgroundsOptions: [],
				focusAreasOptions: [],
				preferenceRoundOptions: [],
				industryOptions: [],
				businessIncomeOptions: [],
				netProfitOptions: []
			},
			geoLogyOptions: []
		}
	},
	computed: {
		...mapState(['labelOptions', 'labelGeoLogyOptions'])
	},
	mounted() {
		this.getLabels()
		this.getGeoLogy()
	},
	methods: {
		...mapMutations(['setLabelOptions', 'setLabelGeoLogyOptions']),
		// 获取标签列表
		getLabels() {
			const { labelOptions } = this
			// selects为组件要查的少选项，对selectArr进行过滤
			this.selects.forEach(({ key, value: labelGroupId }) => {
				if (labelOptions[`${key}Options`].length) {
					return this.options[`${key}Options`] =labelOptions[`${key}Options`]
				}
				this.$http.Get(this.$api.listByGroupId, { labelGroupId }).then(({ data = [] }) => {
					this.options[`${key}Options`] = data
					this.setLabelOptions({key: `${key}Options`, list: data})
				})
			})
		},
		// 获取省市区
		getGeoLogy() {
			const { labelGeoLogyOptions } = this
			if (labelGeoLogyOptions.length) {
				return this.geoLogyOptions = labelGeoLogyOptions
			}
			this.$http.Get(this.$api.geologyTree, {}).then(({data = []}) => {
				this.geoLogyOptions = (data?.children || []).reduce((p, n) => {
					return [
						...p,
						{
							...n,
							children: (n.children || []).map(i => {
								// 删除区，只保留省市
								const item = { ...i }
								delete item.children
								return item
							})
						}
					]
				}, [])
				this.setLabelGeoLogyOptions(this.geoLogyOptions)
			})
		},
	}
}