<template>
    <el-dialog class="release"
        :visible.sync="dialogVisible"
        :close-on-click-modal="!uploadIng"
        :close-on-press-escape="!uploadIng"
        width="780px"
        z-index="1000"
        append-to-body
        top="5vh"
        @closed="closed"
        v-bind="$attrs"
        title="发布项目">
        <div class="release-body">
            <el-form :model="formData" label-position="top" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
                <el-form-item label="公司名称" prop="enterpriseName">
                    <el-autocomplete v-model="formData.enterpriseName" :fetch-suggestions="querySearchAsync"
                        placeholder="请输入工商注册名称（全称）" @select="enterpriseNameHandleSelect" style="width: 100%;"
                        value-key="enterpriseName" clearable></el-autocomplete>
                </el-form-item>
                <el-form-item label="公司所在地" prop="address">
                    <el-cascader placeholder="选择公司所在地" filterable  v-model="formData.address" style="width: 100%;" :options="geoLogyOptions" :props="{
                        label: 'name',
                        value: 'id'
                    }" @change="addressChange" :disabled="disabled" clearable></el-cascader>
                </el-form-item>
                <el-form-item label="行业关键词" prop="industry">
                    <el-select v-model="formData.industry" multiple placeholder="选择行业关键词"  style="width: 100%;" :multiple-limit="3" :disabled="disabled" clearable>
                        <el-option
                        v-for="item in options.industryOptions"
                        :key="item.id"
                        :label="item.labelName"
                        :value="item.id">
                        </el-option>
                    </el-select>
                    <!-- <el-cascader v-model="formData.industry" placeholder="选择行业关键词" style="width: 100%;" :props="{
                        label: 'labelName',
                        value: 'id'
                    }" :options="options.industryOptions" :disabled="disabled" clearable></el-cascader> -->
                </el-form-item>
                <el-form-item label="最近财年营业总收入" prop="businessIncome">
                    <el-cascader v-model="formData.businessIncome" placeholder="选择最近财年营业总收入" style="width: 100%;"
                        :props="{
                            label: 'labelName',
                            value: 'id'
                        }" :options="options.businessIncomeOptions" :disabled="disabled" clearable></el-cascader>
                </el-form-item>
                <el-form-item label="最近财年净利润" prop="businessIncome">
                    <el-cascader v-model="formData.netProfit" placeholder="选择最近财年净利润" style="width: 100%;" :props="{
                        label: 'labelName',
                        value: 'id'
                    }" :options="options.netProfitOptions" :disabled="disabled" clearable></el-cascader>
                </el-form-item>
                <el-form-item label="选择项目类型" prop="investProjectAttachments" v-loading="uploadIng">
                    <el-select v-model="tab" placeholder="请选择" @change="tabChane" style="width: 100%;">
                        <el-option
                        v-for="item in tabOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        :disabled="item.disabled">
                        </el-option>
                    </el-select>
                    <!-- <el-radio-group @input="tabChane" v-model="tab">
                        <el-radio-button :label="item.value" v-for="item in tabOptions" :key="item.value" :disabled="item.disabled">{{item.label}}</el-radio-button>
                    </el-radio-group> -->
                    <div class="release-file">
                        <!-- 附件上传 -->
                        <template v-if="tab != '6'">
                            <div class="file-list" v-for="(item, index) in fileList" :key="index">
                                <img class="fileIcon" :src="coverMap[item.fileType]" />
                                <div class="des">
                                    <div class="title">{{item.originName || item.fileName}}</div>
                                    <div class="size">{{formatSize(item.fileSize || 0)}}</div>
                                </div>
                                <div class="del" @click.stop="handleRemove(item)">
                                    <i class="el-icon-delete"></i>
                                    <!-- <uni-icons class="navigat-arrow" color="#ccc" type="trash" size="20"></uni-icons> -->
                                </div>
                            </div>
                            <el-upload
                                v-if="!fileList.length && tab"
                                class="upload-demo"
                                :show-file-list="false"
                                ref="fileUpload"
                                list-type="picture"
                                accept=".pdf,.doc,.docx,.word,.ppt,.pptx"
                                :action="baseRequestUrl + $api.clientUploadAttachment"
                                :headers="{
                                    token
                                }"
                                multiple
                                :limit="1"
                                :on-success="uploadSuccess"
                                :before-upload="beforeUpload"
                                :on-progress="onProgress"
                                :on-error="onError"
                                :file-list="fileList"
                                :on-remove="handleRemove">
                                <el-button  size="small" plain type="primary">点击上传</el-button>
                                <!-- <div slot="tip" class="el-upload__tip">只能上传pdf/doc/ppt文件</div> -->
                            </el-upload>
                            <!-- <el-empty v-if="!fileList.length" description="暂无数据" :image-size="100"></el-empty> -->
                        </template>
                        <!-- 联系人 -->
                        <template v-else>
                            <el-form :model="enterpriseConcatFormData" ref="enterpriseForm" label-position="top">
                                <el-form-item label="联系人" prop="enterpriseConcatName">
                                    <el-input v-model="enterpriseConcatFormData.enterpriseConcatName" placeholder="请输联系人"></el-input>
                                </el-form-item>
                                <el-form-item label="职务" prop="enterpriseConcatPosition">
                                    <el-input v-model="enterpriseConcatFormData.enterpriseConcatPosition" placeholder="请输联职务"></el-input>
                                </el-form-item>
                                <el-form-item label="联系人手机号" prop="enterpriseConcatPhone">
                                    <el-input v-model="enterpriseConcatFormData.enterpriseConcatPhone" placeholder="请输联系人手机号"></el-input>
                                </el-form-item>
                                <el-form-item label="联系人名片" prop="enterpriseConcatBusinessCard">
                                    <el-upload
                                        list-type="picture-card"
                                        ref="cardUpload"
                                        :limit="1"
                                        :action="baseRequestUrl + $api.uploadImage"
                                        :headers="{
                                            token
                                        }"
                                        :on-preview="cardHandlePictureCardPreview"
                                        :on-remove="cardHandleRemove"
                                        :on-success="cardUploadSuccess">
                                        <i class="el-icon-plus" v-if="!enterpriseConcatFormData.enterpriseConcatBusinessCard"></i>
                                    </el-upload>
                                </el-form-item>
                            </el-form>
                        </template>
                    </div>
                </el-form-item>
            </el-form>
            <div class="footer">
                <el-button type="primary" @click="submit">发布</el-button>
            </div>
        </div>
        <!-- <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submit">确 定</el-button>
        </span> -->
        <el-dialog append-to-body :visible.sync="previewVisible">
            <img width="100%" :src="enterpriseConcatFormData.enterpriseConcatBusinessCard" alt="">
        </el-dialog>
    </el-dialog>
</template>

<script>
import getLabelsMixins from '@/pages/layout/getLabelsMixins.js'
import { baseRequestUrl } from '@/public/api.js'
import { mapState } from 'vuex'
const coverMap = {
	1: require('@/assets/fileIcon/pdf.png'),
	2: require('@/assets/fileIcon/word.png'),
	3: require('@/assets/fileIcon/xsl.png')
}
export default {
    name: 'release',
    data() {
        return {
            uploadIng: false,
            coverMap,
            baseRequestUrl,
            dialogVisible: false,
            tab: '',
            enterpriseName: [],
            previewVisible: false,

            enterpriseConcatFormData: {
                enterpriseConcatName: '',
                enterpriseConcatPhone: '',
                enterpriseConcatBusinessCard: '',
                enterpriseConcatPosition: ''
            },
            formData: {
                // 地址数组
                address: [],
                // 公司名称
                enterpriseName: '',
                // 省
                provinceCode: '',
                provinceName: '',
                // 市
                cityCode: '',
                cityName: '',
                // 行业关键词
                industry: [],
                // 最近财年营业总收入
                businessIncome: [],
                // 最近财年净利润
                netProfit: [],
                // 附件
                investProjectAttachments: []
            },
            rules: {
                enterpriseName: [
                    { required: true, message: '请输公司名称', trigger: 'blur' }
                ],
                address: [
                    { required: true, message: '请选择公司所在地', trigger: 'change' }
                ],
                industry: [
                    {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (this.readonly) return callback()
                            if (value.length < 2 || value.length > 3) {
                                callback(new Error('请至少勾选两个，最多三个关键词'))
                            }
                            return callback()
                        },
                        trigger: 'change'
                    }
                ],
                businessIncome: [
                    { required: true, message: '请选择最近财年营业总收入', trigger: 'change' }
                ],
                netProfit: [
                    { type: 'array', required: true, message: '请选择最近财年净利润', trigger: 'change' }
                ],
                investProjectAttachments: [
                    {
                        trigger: 'change',
                        required: true,
                        validator: (rule, value, callback) => {
                            const { tab, fileList } = this
                            const { enterpriseConcatName, enterpriseConcatPhone } = this.enterpriseConcatFormData
                            if (tab !==6 && !fileList.length) return callback(new Error('请上传附件'));
                            if (tab === 6 && (!enterpriseConcatName || !enterpriseConcatPhone)) return callback(new Error('请填写完整联系人'));
                            callback()
                        }
                    }
                ]
            },
            checkData: {},
            countData: {}
        }
    },
    mixins: [getLabelsMixins],
    computed: {
        ...mapState(['token']),
        selects() {
            return [1, 2, 3].map(i => this.selectArr.find(item => item.value === i))
        },
        disabled() {
            return this.checkData.enterpriseName === this.formData.enterpriseName
        },
        tabOptions() {
            const { countData } = this
            const keys = ['bpEnable' ,'interviewSummaryEnable', 'projectProposalReportEnable', 'dueDiligenceReportEnable', 'investmentDecisionReportEnable', 'enterpriseConcatEnable']
            return ['项目bp', '访谈纪要', '立项报告', '尽调报告', '投决报告', '联系方式'].map((label, index) => {
                const disabled = !countData[keys[index]] && this.disabled
                return {
                    label,
                    value: index+1,
                    // count,
                    disabled
                }
            })
        },
        fileList() {
            const { tab } = this
            if (tab != '6') {
                return this.formData.investProjectAttachments.filter(i => i.attachmentType == tab).map(i => {
                    const { attachmentType, fileName, fileType, fileUrl, fileSizeStr, fileSize, id, cover } = i
                    return {
                        attachmentType,
                        url: coverMap[i.fileType],
                        name: fileName,
                        fileName,
                        fileType,
                        fileUrl,
                        fileSizeStr,
                        fileSize,
                        id,
                        cover
                    }
                })
            } else {
                const { enterpriseConcatName, enterpriseConcatPhone, enterpriseConcatBusinessCard, enterpriseConcatPosition } = this.enterpriseConcatFormData
                return [{
                    attachmentType: tab,
                    enterpriseConcatName,
                    enterpriseConcatPhone,
                    enterpriseConcatBusinessCard,
                    enterpriseConcatPosition
                }]
            }
        }
    },
    watch: {
        tabOptions: {
            handler() {
                // this.tab = this.tabOptions.find(i => !i.disabled)?.value || ''
            },
            immediate: true,
            deep: true
        },
        disabled() {
            if (!this.disabled) {
                this.formData.investProjectAttachments = []
            }
        }
    },
    methods: {
        async init() {
            this.$refs.ruleForm?.clearValidate()
            this.dialogVisible = true
        },
        formatSize(data) {
            const val = Number(data || 0)
            if (!val) return '0 B';
            const k = 1024;
            const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
            const i = Math.floor(Math.log(val) / Math.log(k));
            return parseFloat((val / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
        },
        tabChane() {
            this.$refs.ruleForm.clearValidate(['investProjectAttachments'])
        },
        cardHandlePictureCardPreview() {
            this.previewVisible = true
        },
        cardHandleRemove(file) {
            console.log(file)
        },
        // 联系人名片上传
        cardUploadSuccess({ code, data, msg }, { uid })  {
            if (code !== 200) {
                let idx = this.$refs.cardUpload.uploadFiles.findIndex(item => item.uid === uid)
                this.$refs.cardUpload.uploadFiles.splice(idx, 1)
                return this.$message.error(msg)
            }
            const { fileUrl } = data
            this.enterpriseConcatFormData.enterpriseConcatBusinessCard = fileUrl
        },
        // 公司名称搜索点击
        async enterpriseNameHandleSelect({ id }) {
            const { data } = await this.$http.Get(this.$api.clientProjectDetail, {id, show: 1})
            this.checkData = data
            this.tab = ''
            // const { enterpriseName = '', provinceCode = '', provinceName = '', cityCode = '', cityName = '', industry = '', businessIncome = '', netProfit } = data
            Object.keys(this.formData).forEach(key => {
                if (['businessIncome', 'netProfit'].includes(key)) {
                    return this.formData[key] = data[key].split(',').filter(i => i)
                }
                if (key == 'industry') {
                    return this.formData[key] = data.industryList
                }
                if (key === 'investProjectAttachments') {
                    return this.formData[key] = []
                }
                if (key === 'address') {
                    console.log(data.provinceCode, data.cityCode)
                    return this.formData[key] = [data.provinceCode, data.cityCode]
                }
                this.formData[key] = data[key]
            })
            this.$http.Get(this.$api.getAttachmentCount, {projectId: data.id }).then(({ data }) => {
                this.countData = data
            })

        },
        // 公司名称搜索
        querySearchAsync(queryString, cb) {
            if (queryString) {
                const { enterpriseName } = this.formData
                this.$http.Post(this.$api.getProjectInfoByCondition, { enterpriseName }).then(({ data }) => {
                    cb(data)
                })
            } else {
                cb([])
            }
        },
        addressChange(e) {
            const { geoLogyOptions } = this
            const [ provinceCode, cityCode ] = e
            const activeData = geoLogyOptions.find(({ id }) => id === provinceCode)
            this.formData.provinceCode = provinceCode || ''
            this.formData.cityCode = cityCode || ''
            this.formData.provinceName = activeData?.name || ''
            this.formData.cityName = (activeData?.children || []).find(({ id }) => id === cityCode)?.name || ''
            
        },
        beforeUpload(e) {
            console.log(e)
            this.uploadIng = true

        },
        onError() {
            this.uploadIng = false
        },
        onProgress() {
            
        },
        handleRemove({ id }) {
            const { investProjectAttachments } = this.formData
            this.formData.investProjectAttachments = investProjectAttachments.filter(i => i.id != id)
        },
        uploadSuccess({code, data, msg}, { uid }) {
            this.uploadIng = false
            console.log(data)
            if (code !== 200) {
                let idx = this.$refs['fileUpload'].uploadFiles.findIndex(item => item.uid === uid)
                this.$refs.fileUpload.uploadFiles.splice(idx, 1)
                return this.$message.error(msg)
            }
            const { fileType, originName, fileUrl, fileSizeStr, fileSize, id, cover } = data
            this.$refs.ruleForm.clearValidate('investProjectAttachments')
            this.formData.investProjectAttachments.push({
                attachmentType: this.tab,
                url: coverMap[fileType],
                name: originName,
                fileName: originName,
                fileType,
                fileUrl,
                fileSizeStr,
                fileSize,
                id,
                originName,
                cover
            })
        },
        resetData() {
            this.tab = ''
            this.enterpriseName = []
            this.previewVisible = false
            this.uploadIng = false
            this.enterpriseConcatFormData = {
                enterpriseConcatName: '',
                enterpriseConcatPhone: '',
                enterpriseConcatBusinessCard: '',
                enterpriseConcatPosition: ''
            }
            this.formData = {
                // 公司名称
                enterpriseName: '',
                address: [],
                // 省
                provinceCode: '',
                provinceName: '',
                // 市
                cityCode: '',
                cityName: '',
                // 行业关键词
                industry: [],
                // 最近财年营业总收入
                businessIncome: [],
                // 最近财年净利润
                netProfit: [],
                // 附件
                investProjectAttachments: []
            }
            this.checkData = {}
            this.countData = {}

        },
        closed() {
            this.resetData()
            this.$refs.ruleForm.clearValidate()
        },
        async submit() {
            try {
                await this.$refs.ruleForm.validate()
                const { disabled, fileList, checkData, formData } = this
                const { enterpriseName, provinceCode, provinceName, cityCode, cityName, industry, netProfit, businessIncome } = formData
                const requestUrl = disabled ? this.$api.clientInvestProjectUpload : this.$api.clientProjectAdd
                const params = {}
                delete params.investProjectAttachments
                params.investProjectAttachments = fileList;
                ['industry', 'businessIncome', 'netProfit'].forEach(key => {
                    params[key] = (params[key] || []).toString()
                })
                if (disabled) {
                    params.id = checkData.id
                    // const investProjectAttachments = tab != '6' ? formData.investProjectAttachments.filter(i => i.attachmentType == tab) : this.fileList
                    // params.investProjectAttachments = formData.investProjectAttachments.filter(i => i.attachmentType == tab)
                    params.investProjectAttachments = this.fileList
                } else {
                    params.enterpriseName = enterpriseName
					params.provinceCode = provinceCode
					params.provinceName = provinceName
					params.cityCode = cityCode
					params.cityName = cityName
					params.industryList = industry
					params.netProfit = netProfit.toString()
					params.businessIncome = businessIncome.toString()
					// params.enterpriseContact = enterpriseContact
					// params.enterpriseContactWay = enterpriseContactWay
					// params.enterpriseName = enterpriseName
					params.investProjectAttachments = this.fileList
					params.cover = this.fileList[0]?.cover
                    // ...this.formData
                    // params.cover = this.fileList[0]?.cover
                }
                delete params.industry
                this.$http.Post(requestUrl, params).then(({ code, msg }) => {
                    if (code === 200) {
                        this.$message.success(msg)
                        this.$bus.$emit('releaseSuccess')
                        this.dialogVisible = false
                    }
                })
            } catch(e) {
                console.log(e)
            }
        }
    }
}
</script>
<style lang="less" scoped>
.release-body {
    width: 533px;
    margin: 0 auto;
}
/deep/.el-dialog{
    border-radius: 12px;
    .el-dialog__header{
        text-align: center;
    }
    .el-form--label-top .el-form-item__label{
        line-height: 18px;
        padding-bottom: 8px;
    }
}
.release-file{
    min-height: 200px;
    position: relative;
    background-color: #F6F6F6;
    border-radius: 0 0 12px 12px;
    padding: 20px;
    box-sizing: border-box;
    ::v-deep  .el-upload-list__item{
        transition:none !important;
        -webkit-transition:nonne !important;
    }
    ::v-deep   .el-upload-list__item-name{
        transition:none !important;
        -webkit-transition:nonne !important;
    }
    .upload-demo{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        .el-button{
            background-color: #E3ECFF;
            border-color: #E3ECFF;
            color: #1c68ff;
            font-size: 14px;
            font-weight: 500;
        }
    }
    
    .file-list{
        display: flex;
        line-height: 1.5;
        align-items: center;
        .fileIcon{
            width: 70px;
            height: 70px;
            margin-right: 10px;
        }
        .des{
            flex: 1;
            width: 0;
            .title{
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
.footer{
    text-align: center;
    .el-button{
        width: 187px;
        height: 44px;
        background-color: #1C68FF;
    }
}
</style>