var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',_vm._b({staticClass:"release",attrs:{"visible":_vm.dialogVisible,"close-on-click-modal":!_vm.uploadIng,"close-on-press-escape":!_vm.uploadIng,"width":"780px","z-index":"1000","append-to-body":"","top":"5vh","title":"发布项目"},on:{"update:visible":function($event){_vm.dialogVisible=$event},"closed":_vm.closed}},'el-dialog',_vm.$attrs,false),[_c('div',{staticClass:"release-body"},[_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.formData,"label-position":"top","rules":_vm.rules,"label-width":"150px"}},[_c('el-form-item',{attrs:{"label":"公司名称","prop":"enterpriseName"}},[_c('el-autocomplete',{staticStyle:{"width":"100%"},attrs:{"fetch-suggestions":_vm.querySearchAsync,"placeholder":"请输入工商注册名称（全称）","value-key":"enterpriseName","clearable":""},on:{"select":_vm.enterpriseNameHandleSelect},model:{value:(_vm.formData.enterpriseName),callback:function ($$v) {_vm.$set(_vm.formData, "enterpriseName", $$v)},expression:"formData.enterpriseName"}})],1),_c('el-form-item',{attrs:{"label":"公司所在地","prop":"address"}},[_c('el-cascader',{staticStyle:{"width":"100%"},attrs:{"placeholder":"选择公司所在地","filterable":"","options":_vm.geoLogyOptions,"props":{
                    label: 'name',
                    value: 'id'
                },"disabled":_vm.disabled,"clearable":""},on:{"change":_vm.addressChange},model:{value:(_vm.formData.address),callback:function ($$v) {_vm.$set(_vm.formData, "address", $$v)},expression:"formData.address"}})],1),_c('el-form-item',{attrs:{"label":"行业关键词","prop":"industry"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"multiple":"","placeholder":"选择行业关键词","multiple-limit":3,"disabled":_vm.disabled,"clearable":""},model:{value:(_vm.formData.industry),callback:function ($$v) {_vm.$set(_vm.formData, "industry", $$v)},expression:"formData.industry"}},_vm._l((_vm.options.industryOptions),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.labelName,"value":item.id}})}),1)],1),_c('el-form-item',{attrs:{"label":"最近财年营业总收入","prop":"businessIncome"}},[_c('el-cascader',{staticStyle:{"width":"100%"},attrs:{"placeholder":"选择最近财年营业总收入","props":{
                        label: 'labelName',
                        value: 'id'
                    },"options":_vm.options.businessIncomeOptions,"disabled":_vm.disabled,"clearable":""},model:{value:(_vm.formData.businessIncome),callback:function ($$v) {_vm.$set(_vm.formData, "businessIncome", $$v)},expression:"formData.businessIncome"}})],1),_c('el-form-item',{attrs:{"label":"最近财年净利润","prop":"businessIncome"}},[_c('el-cascader',{staticStyle:{"width":"100%"},attrs:{"placeholder":"选择最近财年净利润","props":{
                    label: 'labelName',
                    value: 'id'
                },"options":_vm.options.netProfitOptions,"disabled":_vm.disabled,"clearable":""},model:{value:(_vm.formData.netProfit),callback:function ($$v) {_vm.$set(_vm.formData, "netProfit", $$v)},expression:"formData.netProfit"}})],1),_c('el-form-item',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.uploadIng),expression:"uploadIng"}],attrs:{"label":"选择项目类型","prop":"investProjectAttachments"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择"},on:{"change":_vm.tabChane},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value,"disabled":item.disabled}})}),1),_c('div',{staticClass:"release-file"},[(_vm.tab != '6')?[_vm._l((_vm.fileList),function(item,index){return _c('div',{key:index,staticClass:"file-list"},[_c('img',{staticClass:"fileIcon",attrs:{"src":_vm.coverMap[item.fileType]}}),_c('div',{staticClass:"des"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.originName || item.fileName))]),_c('div',{staticClass:"size"},[_vm._v(_vm._s(_vm.formatSize(item.fileSize || 0)))])]),_c('div',{staticClass:"del",on:{"click":function($event){$event.stopPropagation();return _vm.handleRemove(item)}}},[_c('i',{staticClass:"el-icon-delete"})])])}),(!_vm.fileList.length && _vm.tab)?_c('el-upload',{ref:"fileUpload",staticClass:"upload-demo",attrs:{"show-file-list":false,"list-type":"picture","accept":".pdf,.doc,.docx,.word,.ppt,.pptx","action":_vm.baseRequestUrl + _vm.$api.clientUploadAttachment,"headers":{
                                token: _vm.token
                            },"multiple":"","limit":1,"on-success":_vm.uploadSuccess,"before-upload":_vm.beforeUpload,"on-progress":_vm.onProgress,"on-error":_vm.onError,"file-list":_vm.fileList,"on-remove":_vm.handleRemove}},[_c('el-button',{attrs:{"size":"small","plain":"","type":"primary"}},[_vm._v("点击上传")])],1):_vm._e()]:[_c('el-form',{ref:"enterpriseForm",attrs:{"model":_vm.enterpriseConcatFormData,"label-position":"top"}},[_c('el-form-item',{attrs:{"label":"联系人","prop":"enterpriseConcatName"}},[_c('el-input',{attrs:{"placeholder":"请输联系人"},model:{value:(_vm.enterpriseConcatFormData.enterpriseConcatName),callback:function ($$v) {_vm.$set(_vm.enterpriseConcatFormData, "enterpriseConcatName", $$v)},expression:"enterpriseConcatFormData.enterpriseConcatName"}})],1),_c('el-form-item',{attrs:{"label":"职务","prop":"enterpriseConcatPosition"}},[_c('el-input',{attrs:{"placeholder":"请输联职务"},model:{value:(_vm.enterpriseConcatFormData.enterpriseConcatPosition),callback:function ($$v) {_vm.$set(_vm.enterpriseConcatFormData, "enterpriseConcatPosition", $$v)},expression:"enterpriseConcatFormData.enterpriseConcatPosition"}})],1),_c('el-form-item',{attrs:{"label":"联系人手机号","prop":"enterpriseConcatPhone"}},[_c('el-input',{attrs:{"placeholder":"请输联系人手机号"},model:{value:(_vm.enterpriseConcatFormData.enterpriseConcatPhone),callback:function ($$v) {_vm.$set(_vm.enterpriseConcatFormData, "enterpriseConcatPhone", $$v)},expression:"enterpriseConcatFormData.enterpriseConcatPhone"}})],1),_c('el-form-item',{attrs:{"label":"联系人名片","prop":"enterpriseConcatBusinessCard"}},[_c('el-upload',{ref:"cardUpload",attrs:{"list-type":"picture-card","limit":1,"action":_vm.baseRequestUrl + _vm.$api.uploadImage,"headers":{
                                        token: _vm.token
                                    },"on-preview":_vm.cardHandlePictureCardPreview,"on-remove":_vm.cardHandleRemove,"on-success":_vm.cardUploadSuccess}},[(!_vm.enterpriseConcatFormData.enterpriseConcatBusinessCard)?_c('i',{staticClass:"el-icon-plus"}):_vm._e()])],1)],1)]],2)],1)],1),_c('div',{staticClass:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v("发布")])],1)],1),_c('el-dialog',{attrs:{"append-to-body":"","visible":_vm.previewVisible},on:{"update:visible":function($event){_vm.previewVisible=$event}}},[_c('img',{attrs:{"width":"100%","src":_vm.enterpriseConcatFormData.enterpriseConcatBusinessCard,"alt":""}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }